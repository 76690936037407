import React from "react";
import { FaInstagram, FaTwitter, FaFacebookF, FaLink, FaRegEnvelope, FaChevronDown, FaCheck, FaPaperPlane, FaTimes } from "react-icons/fa";
import "./markdown.scss";
import "./style.scss";

interface IProps {
  html: string,
  toc?: any,
  slug: string,
  frontmatter: {
    title: string,
    date: string,
    excerpt: string,
    author: string
    keywords: string
  }
  callbackFromParent: (msg: string, value: any) => void
}

let initialState = {
  scrollPosition: 0,
  currentTopOffset: 0,
  email: "",
  success: false,
  failure: false,
  errorMsg: "",
  busy: false
};

type State = typeof initialState;

class Markdown extends React.Component<IProps, State> {
  state = initialState;
  props: IProps;
  headers = []; // NodeListOf<Element>;
  tocElements = []; // NodeListOf<Element>;
  tocOriginalColors = [];

  onScroll: () => void;
  constructor(props: IProps) { //{ title, date, html, toc }) {
    super(props);
    this.onScroll = this.handleScroll.bind(this);
  }

  render() {
    let parent = `pt-10 max-w-screen-lg`;
    let progressContainer = `fixed w-full h-2 max-w-screen-lg`;
    let progress = `bg-orange-400 h-2 max-w-screen-lg`
    let container = `flex flex-row flex-wrap lg:justify-center md:justify-center p-2`;
    let html = `
      flex-grow mr-2 pb-4 md:pr-4
      border-b-2 border-gray-800 
      lg:border-b-0
      lg:border-r-2 lg:border-gray-400`;

    let social = `flex justify-center items-center rounded-full w-6 h-6 border text-gray-800 border-gray-600 mr-2 text-sm`;
    let button = `
      p-2 mt-6 rounded-lg shadow-lg border border-red-600 flex-grow-1 flex-grow-0 
      font-condensed uppercase  text-red-800 text-left
    `;

    let inputDefaultStyle = "w-32 h-8 my-2 px-2 text-gray-800 outline-none font-sans text-sm rounded";
    let invalidStyle = "border border-red-500";
    let validatedStyle = "border border-teal-500";
    let submitInvalid = "bg-gray-200 text-gray-800 my-2 px-2 ";
    let submitValid = "bg-green-200 text-gray-800 my-2 px-2 border-green-500";

    let link = `https://www.sumergoapp.com/blog${this.props.slug}`;
    return (
      <div className={parent}>
        <div className={progressContainer}>
          <div className={progress} style={{ width: `${this.state.scrollPosition}%` }}></div>
        </div>
        <div className={container}>
          <div className="flex flex-col">
            {/* Title, Date, and Keywords*/}
            <div className="pb-4">
              <div className="flex flex-row flex-wrap m-0">
                <div className="opensans text-xs text-orange-500 flex-shrink  m-0 mr-3 rounded-sm hover:text-black">{this.props.frontmatter.keywords.split(",")[0].toUpperCase()}</div>
              </div>
              <h1 className="text-left m-0 p-0 leading-tight"><b>{this.props.frontmatter.title}</b></h1>
              <p className="text-md m-0">
                {this.props.frontmatter.date}
              </p>
              <p className="text-md m-0">
                {this.props.frontmatter.author}
              </p>
            </div>
            {/* Html & Links */}
            <div className="flex flex-col lg:flex-row">
              {/* Html */}
              <section className={html} id="markdown_html">
                <div dangerouslySetInnerHTML={{ __html: this.formatHTML(this.props.html) }} />
              </section>
              {/* Links */}
              <div></div>
              <div className="pb-4 flex w-full items-center flex-col">
                {(this.props.frontmatter.title.indexOf("Terms") > -1) || (this.props.frontmatter.title.indexOf("Privacy") > -1) ? "" : (
                  <div className="max-w-screen-sm flex flex-col items-center w-48">
                    <p className="">
                      Share this article
                    </p>
                    <div className="flex flex-row flex-wrap">

                      <div className={social}>
                        <a href="http://www.instagram.com/sumergoapp/"><FaInstagram /></a>
                      </div>
                      <div className={social}>
                        <a href="https://www.facebook.com/sumergoapp/"><FaFacebookF /></a>
                      </div>
                      <div className={social}>
                        <a href="https://www.twitter.com/sumergoapp/"><FaTwitter /></a>
                      </div>
                      <div className={social}>
                        <a href={link}><FaLink /></a>
                      </div>
                      <div className={social}>
                        <a href={`mailto:?subject=${this.props.frontmatter.title}&body=${link}`}><FaRegEnvelope /></a>
                      </div>
                    </div>
                    <br></br>
                    <div className="font-sans text-sm" onClick={this.toggleMailing}>Receive News &amp; Updates</div>
                    <form onSubmit={this.handleSubmit}>
                      <div className="flex-grow flex flex-row items-center justify-center">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          id="email"
                          className={`${inputDefaultStyle} ${this.validateEmail() ? validatedStyle : invalidStyle} rounded-r-none`} onChange={this.handleEmailChange}
                        />
                        <button type="submit" className={`${inputDefaultStyle} w-32 rounded-l-none text-xs ${this.validateEmail() ? submitValid : submitInvalid} w-full`} onChange={this.handleSubmit}>
                          <div className="">
                            <div className={`flex flex-grow`}>
                              {!this.state.busy && !this.state.failure && !this.state.success ? <FaPaperPlane></FaPaperPlane> : ""}
                              {(!this.state.busy && this.state.success) ? <FaCheck></FaCheck> : ""}
                              {(!this.state.busy && this.state.failure) ? <FaTimes></FaTimes> : ""}
                              {this.state.busy ? (<svg className={`svg-spinner linear-infinite`} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle className="circle" cx="50" cy="50" r="45" /></svg>) : ""}
                            </div>
                          </div>
                        </button>
                      </div>
                      <div className={`${this.state.failure ? '' : 'hidden'} text-red-500`}>  {this.state.errorMsg}</div>
                    </form>
                  </div>
                ) }

              </div>
              {/* End of Links */}
            </div>
            {/* End of Html + Link */}
          </div>
        </div>
      </div>
    );
  }

  toggleMailing = () => {
    this.props.callbackFromParent('mailingActive', true);
    //this.setState({mailingActive: true});
  }

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  }

  handleScroll = () => {
    requestAnimationFrame(function () {
      let docHeight = document.getElementById('markdown_html').clientHeight; //this.getDocHeight();
      let totalDocScrollLength = docHeight - window.innerHeight;
      let scrollPosition = Math.min(Math.floor(window.pageYOffset / totalDocScrollLength * 100), 100);
      this.setState({ scrollPosition: scrollPosition });
    }.bind(this));
  }

  handleSubmit = event => {
    this.setState({ failure: false, success: false })
    event.preventDefault();
    this.setState({ busy: true });
    fetch("https://aq12uvr183.execute-api.us-east-1.amazonaws.com/dev/mailManage", {
      method: 'POST',
      body: JSON.stringify({
        emails: [this.state.email],
        action: "add"
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status === 200) {
          this.setState({ success: true, busy: false, errorMsg: response.body['errorMsg'] });
        } else {
          this.setState({ failure: true, errorMsg: response.body['errorMsg'], busy: false })
        }
      })
      .catch((error) => {
        this.setState({ failure: true, success: false, busy: false, errorMsg: "An unknown error has occurred" });
      });
  }
  getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  formatHTML(html: string) {
    html = html.replace(/<h1.+\/h1>/g, '');
    return html
  }

  formatTOC(toc) {
    //toc = this.replaceAll(toc, '<a', '<p');
    //toc = this.replaceAll(toc, '</a>', '</p>');
    return toc;
  }
  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
  replaceAll(str, term, replacement) {
    return str.replace(new RegExp(this.escapeRegExp(term), 'g'), replacement);
  }
  validateEmail = () => {
    if (this.state.email.indexOf('@') > 1) {
      return true;
    } else { return false; }
  }
}

function addStyleClasses(html) {
  html = html.replace('<h1>', `<h1>`);
  html = html.replace('<h2>', `<h2>`);
  html = html.replace('<h3>', `<h3>`);
  return html;
}

export default Markdown;
