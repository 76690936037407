import React from "react";

import "../components/style.scss";
import Helmet from "../components/helmet";
import Header from "../components/header";
import Navbar from "../components/navbar";
import Markdown from "../components/markdown";
import Footer from "../components/footer";
import { graphql } from "gatsby";
import EmailSignup from "../widgets/email-management/mailing-list";
import { FaPooStorm } from "react-icons/fa";

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        author
        excerpt
        keywords
      }
      tableOfContents
      fields {
        slug
      }
    }
  }
`

let initialState = {
  mailingActive: false
};

let defaultProps = {
  callbackFromParent: (msg: string, value: any) => { },
  displayShare: true
}

type Props = {
  callbackFromParent: (string, any) => void;
}

type  State = {
  mailingActive: boolean;
}

class MarkdownLayout extends React.Component<Props, State> {
  state = initialState;

  constructor(data) {
    super(data);
  }

  handleClick() {
    let a = function () {
      console.log('Click happened');
    }
    a();
  }
  parentCallback = (msg: string, value: any) => {
    if (msg === "mailingActive") {
      if (value === true) {
        this.setState({mailingActive: true});
      }
    }
  }
  render() {
    //@ts-ignore
    const post = this.props.data.markdownRemark;
    return (
  <div className="w-full flex justify-center">
        <div className="max-w-screen-lg w-full">
          <Helmet />
          <Navbar/>
          <Markdown
            html={post.html}
            toc={post.tableOfContents}
            slug={post.fields.slug}
            frontmatter={post.frontmatter}
            callbackFromParent={this.parentCallback} />
          <Footer />
        </div>
      </div>
    );
  }
}

export default MarkdownLayout;
